import { graphql, useStaticQuery } from "gatsby"

const useSketches = () => {
  const data = useStaticQuery(graphql`
    {
      sketches: contentfulComponentSketchFiles {
        images: sketches {
          title
          gatsbyImageData(
            width: 400
            layout: CONSTRAINED
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const sketches = {}

  data.sketches.images.forEach(sketch => {
    sketches[sketch.title] = sketch.gatsbyImageData
  })

  return sketches
}

export default useSketches
