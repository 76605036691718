import React from "react"

import SectionTitle from "~/components/section-title"
import Container from "~/components/container"

const HomeEco = () => {
  return (
    <section className="border-t-3 border-orange border-opacity-100 overflow-hidden">
      <Container>
        <div className="max-w-prose mx-auto">
          <SectionTitle
            title="Sustainability"
            Tag="h2"
            classes={["font-semibold py-4 px-8 text-center md:inline-flex"]}
          />

          <div className="prose sm:prose-sm md:prose-lg lg:prose-xl mx-auto mt-10">
            <p>
              I started this business knowing that I wanted to run it in a way
              that was as sustainable and eco-friendly as possible. I try to
              source as many flowers as I am able from local growers throughout
              the year as well as using flowers from my own cut-flower garden.
              If I can not source what I need locally, I work with a reputable
              Dutch wholesaler only a 10 minute drive away.
            </p>
            <p>
              All my flower packaging has been carefully chosen to be recyclable
              and biodegradable - this includes packaging for wedding
              arrangements. As well as that, I offer the Eco Vase arrangements
              and re-use containers to create candles too!
            </p>
            <p>
              For event work or installations, I wouldn’t ever use floral foam,
              unless a client insisted and within the shop, I don’t use any
              harmful cleaning products or sprays.
            </p>
            <p>
              I also separate and recycle my waste and compost all the green
              waste at the end of the week and most flowers I don’t use while
              fresh, are dried to live another beautiful life!
            </p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomeEco
