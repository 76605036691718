import React from "react"
import ShopCard from "~/components/shop-card"
import SectionTitle from "~/components/section-title"
import Container from "~/components/container"

const HomeShop = ({ data }) => {
  const { title, shopLinks } = data

  const shopTiles = shopLinks.map(link => ({
    title: link.link.text,
    to: link.link.to,
    bgImage: link.image.gatsbyImageData,
  }))

  return (
    <section className="bg-white">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 md:gap-6">
          <div className="xl:col-span-3 xl:col-start-2 lg:mb-10">
            <SectionTitle
              title={title}
              Tag="h2"
              classes={["font-semibold py-4 px-8 text-center md:inline-flex"]}
            />
          </div>

          <div className="col-span-full xl:col-start-2 xl:col-span-10 lg:row-start-2 mt-4">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-8 gap-4 lg:gap-8">
              {shopTiles.map((block, i) => (
                <ShopCard
                  key={i}
                  {...block}
                  classes={[
                    "h-32",
                    "sm:h-96",
                    "lg:col-span-2",
                    "lg:order-none",
                  ]}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomeShop
