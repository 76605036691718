import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "~/components/container"

const HomeFeatured = ({ data, ...otherProps }) => {
  return (
    <section {...otherProps} style={{ backgroundColor: data.backgroundColour }}>
      <Container>
        <div className="flex flex-col justify-center items-center mx-auto md:max-w-prose lg:max-w-screen-md">
          <h2 className="font-title text-center text-white py-4 leading-8 font-semibold flex justify-center text-3xl md:text-4xl lg:text-6xl">
            {data.heading}
          </h2>

          <div className="prose sm:prose-sm md:prose-lg lg:prose-xl max-w-prose mx-auto mb-10 text-center">
            <p className="text-white">{data.subTitle}</p>
          </div>

          <div className="text-center flex flex-col w-full">
            <div className="relative rounded overflow-hidden mb-8 mx-10 md:mx-20 h-72 lg:h-96">
              <GatsbyImage
                image={data.image.gatsbyImageData}
                className="h-full w-full"
                alt="Mother's Day · HeartBee Flowers · Winchester"
                objectPosition="center 46%"
              />
            </div>

            <Link
              to={data.button.to}
              className="py-3 px-5 w-72 mx-auto border border-transparent shadow rounded text-black text-md lg:text-lg font-medium bg-pink mb-10 lg:mb-0"
            >
              {data.button.text}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomeFeatured
