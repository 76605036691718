import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ShopCard = ({ title, bgImage, to, classes }) => {
  const styles = [
    "hover:shadow-2xl transition-shadow duration-200 overflow-hidden relative rounded",
    ...classes,
  ].join(" ")

  return (
    <Link to={to} className={styles}>
      <div className="absolute inset-0 z-10 pointer-events-none py-6 px-4">
        <h3 className="text-white font-primary uppercase text-xl sm:text-2xl font-semibold text-shadow text-center md:text-left">
          {title}
        </h3>
      </div>

      <div className="relative h-full">
        <div className="transform transition ease-in-out duration-200 hover:scale-110 relative h-full">
          <GatsbyImage
            image={bgImage}
            alt={`${title} · HeartBee Flowers · Winchester`}
            className="h-full"
          />
        </div>
      </div>
    </Link>
  )
}

export default ShopCard
