import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import BgImage from "~/components/bg-image"

const NavLink = ({ text, to }) => {
  return (
    <Link
      className="home-hero__navlink block py-2 font-primary text-center uppercase text-orange hover:text-orange-dark font-medium text-2xl"
      to={to}
    >
      {text}
    </Link>
  )
}

const HomeHero = ({ data }) => {
  const { coverImage, logo, siteNav } = data

  return (
    <BgImage
      Tag="section"
      loading="eager"
      className="py-4 md:py-8"
      image={coverImage.gatsbyImageData}
    >
      <div className="container mx-auto px-2 sm:px-6 lg:px-8 flex flex-col md:flex-row md:justify-center items-center">
        <GatsbyImage
          loading="eager"
          image={logo.gatsbyImageData}
          alt="HeartBee Flowers · Winchester"
          className="md:mr-10 w-40 md:w-52"
        />

        <h1 className="font-title font-semibold text-white text-shadow text-6xl md:text-7xl lg:text-8xl text-center mt-4 md:mt-8">
          HeartBee <br />
          <span className="text-5xl md:text-6xl lg:text-7xl">Flowers</span>
        </h1>
      </div>

      <div className="container px-4 sm:px-6 lg:px-8 mt-4 md:mt-10 bg-white bg-opacity-90 max-w-full">
        <nav className="flex md:max-w-screen-md mx-auto items-center justify-between">
          {siteNav.navItems.map(item => (
            <NavLink key={item.to} to={item.to} text={item.text} />
          ))}
        </nav>
      </div>
    </BgImage>
  )
}

export default HomeHero
